import React from 'react'
import { graphql } from 'gatsby'
import { Box, Container } from '@mui/material'

import PageTitleHero, {
  PageTitleHeroProps,
} from '@organisms/hero/PageTitleHero'
import Layout from '@organisms/global/Layout'

import ContentSectionRecentProjects from '@molecules/sections/components/ContentSectionRecentProjects'
import KeywordSwiper from '@organisms/swipers/KeywordSwiper'

interface Props {
  data: Queries.KeywordLandingQuery
}

const KeywordLanding = ({ data }: Props) => {
  const landing = data.datoCmsLandingPage

  // typography="h4" component="h2" ml={{ xs: 3, sm: 8, md: 6 }}

  const headingBoxProps = {
    typography: 'h4',
    component: 'h2',
    ml: { xs: 3, sm: 8, md: 6 },
  }

  return (
    <>
      <Layout
        metadata={{
          seoTags: landing.seoMetaTags,
        }}
        headerProps={{
          sizeVariant: 'compact',
          themeVariant: 'dark',
          forceSolidNav: true,
        }}
      >
        <PageTitleHero
          mainHeadingProps={{ variant: 'h1' }}
          smallHeading={landing.smallHeading}
          mainHeading={landing.mainHeading}
          imageLandscape={landing.imageLandscape.gatsbyImageData}
          imagePortrait={landing.imagePortrait.gatsbyImageData}
          text={landing.heroText}
        />

        <Box
          bgcolor="primary.light"
          position="relative"
          overflow="hidden"
          py={{ xs: 4 }}
        >
          <Container maxWidth="xl">
            <Box {...headingBoxProps}>{data.detailsHeading.name}</Box>
            <KeywordSwiper cards={data.details.edges} />
            <Box {...headingBoxProps}>{data.designHeading.name}</Box>
            <KeywordSwiper cards={data.design.edges} />
            <Box {...headingBoxProps}>{data.coloursFinishesHeading.name}</Box>
            <KeywordSwiper cards={data.coloursFinishes.edges} />
            <Box {...headingBoxProps}>{data.brandsHeading.name}</Box>
            <KeywordSwiper cards={data.brands.edges} />
            <Box {...headingBoxProps}>{data.lifestyleHeading.name}</Box>
            <KeywordSwiper cards={data.lifestyle.edges} />
          </Container>
        </Box>
        <ContentSectionRecentProjects />
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query KeywordLanding {
    datoCmsLandingPage(originalId: { eq: "RpI8-KnLSe-l1bPqjZ6HmQ" }) {
      ...LandingPageTitleHero
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    coloursFinishes: allDatoCmsKeyword(
      filter: {
        category: {
          elemMatch: { originalId: { eq: "ccqGAH9-QWCpSR6CVOU7_g" } }
        }
      }
      sort: { fields: position }
    ) {
      edges {
        node {
          ...cardKeyword
        }
      }
    }
    brands: allDatoCmsKeyword(
      filter: {
        category: {
          elemMatch: { originalId: { eq: "NiLgmQExR2m0vtyHKOPrNQ" } }
        }
      }
      sort: { fields: position }
    ) {
      edges {
        node {
          ...cardKeyword
        }
      }
    }
    design: allDatoCmsKeyword(
      filter: {
        category: {
          elemMatch: { originalId: { eq: "dL2EArIQRcOKAYJhjt8P3g" } }
        }
      }
      sort: { fields: position }
    ) {
      edges {
        node {
          ...cardKeyword
        }
      }
    }
    details: allDatoCmsKeyword(
      filter: {
        category: {
          elemMatch: { originalId: { eq: "NpXPkQ3xRQGUBsXKWqwOhA" } }
        }
      }
      sort: { fields: position }
    ) {
      edges {
        node {
          ...cardKeyword
        }
      }
    }
    lifestyle: allDatoCmsKeyword(
      filter: {
        category: {
          elemMatch: { originalId: { eq: "e8xgQzOrTUyh9MOeZMNgsg" } }
        }
      }
      sort: { fields: position }
    ) {
      edges {
        node {
          ...cardKeyword
        }
      }
    }
    coloursFinishesHeading: datoCmsKeywordCategory(
      originalId: { eq: "ccqGAH9-QWCpSR6CVOU7_g" }
    ) {
      name
    }
    brandsHeading: datoCmsKeywordCategory(
      originalId: { eq: "NiLgmQExR2m0vtyHKOPrNQ" }
    ) {
      name
    }
    designHeading: datoCmsKeywordCategory(
      originalId: { eq: "dL2EArIQRcOKAYJhjt8P3g" }
    ) {
      name
    }
    detailsHeading: datoCmsKeywordCategory(
      originalId: { eq: "NpXPkQ3xRQGUBsXKWqwOhA" }
    ) {
      name
    }
    lifestyleHeading: datoCmsKeywordCategory(
      originalId: { eq: "e8xgQzOrTUyh9MOeZMNgsg" }
    ) {
      name
    }
  }
`

export default KeywordLanding
