import React, { memo } from 'react'
import { Box, Container, useTheme, styled } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
// import SwiperCore from 'swiper'

import 'swiper/css'

import Card from '@molecules/cards/Card'

import {
  DatoCmsKeyword,
  // @ts-ignore
} from '__generated__/gatsby-types'

export interface KeywordSwiperProps {
  cards: DatoCmsKeyword[]
}

const SwiperContainer = styled(Box)(({ theme }) => ({
  ['.swiper-cards']: {
    overflow: 'visible',
  },
  ['.swiper-wrapper']: {
    alignItems: 'stretch',
  },
  ['.swiper-slide']: {
    height: 'auto',
    display: 'flex',
    alignItems: 'stretch',
    opacity: 0.4,
    transition: theme.transitions.create(['transition, opacity']),

    ['&.swiper-slide-active']: {
      opacity: 1,
    },
    [theme.breakpoints.up('md')]: {
      ['&.swiper-slide-active + .swiper-slide']: {
        opacity: 1,
      },
    },
    [theme.breakpoints.up('lg')]: {
      ['&.swiper-slide-active + .swiper-slide + .swiper-slide']: {
        opacity: 1,
      },
    },
  },
  ['.swiper-button-disabled']: {
    opacity: 0.5,
  },
}))

const KeywordSwiper = ({ cards }: KeywordSwiperProps) => {
  const theme = useTheme()

  return (
    <Container maxWidth="xl" sx={{ px: { xs: 3, sm: 8, md: 6, lg: 7, xl: 6 } }}>
      <SwiperContainer>
        <Swiper
          //   onInit={(swiper) => {
          //     // @ts-ignore
          //     swiper.params.navigation.prevEl = prevRef.current
          //     // @ts-ignore
          //     swiper.params.navigation.nextEl = nextRef.current
          //     swiper.navigation.init()
          //     swiper.navigation.update()
          //   }}
          slidesPerView={1}
          observer={true}
          spaceBetween={10}
          observeParents={true}
          freeMode={{
            sticky: false,
          }}
          className="swiper-cards"
          breakpoints={{
            [theme.breakpoints.values.xs]: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            [theme.breakpoints.values.md]: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            [theme.breakpoints.values.lg]: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
        >
          {cards.map((item: any) => {
            const card: Queries.DatoCmsKeyword = item.node
            return (
              <SwiperSlide key={card.originalId}>
                <Card data={card} minimalCard />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </SwiperContainer>
    </Container>
  )
}

export default memo(KeywordSwiper)
